<template>
    <section class="contact">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <a
                        href="https://www.google.com/maps/dir//Vác,+Március+15.+tér+21./"
                        ><img
                            class="img-fluid"
                            src="https://maps.googleapis.com/maps/api/staticmap?center=Vác,+Március+15.+tér+21.&zoom=17&scale=2&size=600x300&maptype=roadmap&key=AIzaSyBj3zrdhF-e3d8tzf91VfvFKhdZuHiScPs&format=png&visual_refresh=true&markers=size:tiny%7Ccolor:0xff0000%7Clabel:1%7CVác,+Március+15.+tér+21."
                            alt="Google Map of Vác, Március 15. tér 21."
                    /></a>
                </div>
                <div
                    class="col-md-6"
                    v-if="shop && Object.keys(shop).length > 0"
                >
                    <h4>Kapcsolat</h4>
                    <div class="contact_right">
                        <span class="margintop">{{ shop.name }}</span>
                        <span>{{ shop.address }}</span>
                        <span
                            ><a :href="'tel:' + shop.phone">{{
                                shop.phone
                            }}</a></span
                        >
                        <span
                            ><a :href="'mailto:' + shop.email">{{
                                shop.email
                            }}</a></span
                        >
                        <div style="margin-top: 40px">
                            <ul class="contact-social" style="padding: 0">
                                <li>
                                    <a
                                        class="fb-ic mr-3"
                                        href="https://www.facebook.com/farmvac"
                                        target="_blank"
                                        role="button"
                                        ><i class="fab fa-lg fa-facebook-f"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        class="tw-ic mr-3"
                                        href="https://www.tripadvisor.com/Restaurant_Review-g1102816-d15322017-Reviews-Farm_Burger_Vac-Vac_Pest_County_Central_Hungary.html"
                                        target="_blank"
                                        role="button"
                                        ><i class="fab fa-lg fa-tripadvisor"></i
                                    ></a>
                                </li>
                                <li v-if="shop && shop.phone">
                                    <a
                                        class="tw-ic mr-3"
                                        :href="'tel:' + shop.phone"
                                        target="_blank"
                                        role="button"
                                        ><i class="fa fab fa-lg fa-phone"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        class="tw-ic mr-3"
                                        href="mailto:info@farmburgervac.hu"
                                        target="_blank"
                                        role="button"
                                        ><i class="fa fab fa-lg fa-envelope"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        class="tw-ic mr-3"
                                        href="https://g.page/farmvac?share"
                                        target="_blank"
                                        role="button"
                                        ><i
                                            class="fa fab fa-lg fa-location-arrow"
                                        ></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                        <div class="voffset-40"></div>
                        <div class="row">
                            <div class="col" v-html="pageData.content"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="voffset-131"></div>
        </div>
    </section>
</template>

<script>
import Static from "./Static";

export default {
    name: "Contact",
    extends: Static,
    data() {
        return {
            shop: {}
        };
    },
    beforeMount() {
        this.$http.get(`${this.url}/shop/get/1`).then(resp => {
            this.shop = resp.data.data;
        });
    }
};
</script>

<style>
.contact {
    color: #fff;
}

.contact_right span {
    display: block;
}

.contact_right a {
    color: #fff;
}

.open_right span {
    width: 49%;
    display: inline-block;
}

.contact-social li {
    list-style: none;
}

.contact-social li {
    display: inline-block;
}
</style>
